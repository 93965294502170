// Alerts
// -----------------------------------------------------------

.breaking-news {
    @include grid-container();
    background: darken($light-gray, 20);
    background: $color-brand;
    color: white;
    padding: 1rem 0;

    .content-wrapper {
        @include grid-column(12);
        position: relative;
    }

    a {
        color: white;

        &:hover,
        &:focus {
            color: rgba(white, .8);
        }

        &:active {
            color: rgba(white, 0.6);
        }
    }

    .left {
        padding-right: 2rem;
    }

    .right {
        position: absolute;
        right: 0;
        top: 0;
    }
}
