// Typography
$sans-serif: Helvetica, Arial, sans-serif;
$serif: Helvetica, Arial, sans-serif;

// Fonts
//$typekit-source-serif: "proxima-nova";
$typekit-source-sans: "ingra";
$fontawesome: "fontawesome";

// Typography Base
$base-font-family: $typekit-source-sans, $sans-serif;
$heading-font-family: $base-font-family;

// Font Weights
$hairline: 100;
$thin: 100;
$light: 200;
$book: 300;
$regular: 400;
$medium: 500;
$semibold: $medium;
$bold: 700;
$heavy: 900;

// Font Sizes
$base-font-size: 1rem;
$base-font-weight: $book;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$red: #c0152f;
$grey: grey;
$dark-grey: #333;
$medium-gray: #999;
$light-gray: #e6e6e6;
$color-brand: #ca872c;
$white: white;
$black: black;
$dark: #222222;

// Element Colors
$block-color-1: #e2dee2;
$block-color-2: #cebda9;
$block-color-3: #383331;

// Font Colors
$base-font-color: $dark-grey;
$base-hover-color: darken($color-brand, 20);
$base-link-color: $color-brand;
$action-color: $color-brand;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);
$base-background-color-light: darken(white, 7);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
$shift-distance: 280px;
