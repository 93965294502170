html {
    font-size: 15px;
}

body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height;
    letter-spacing: .02rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-size: $base-font-size;
    line-height: $heading-line-height;
    margin: 0 0 $small-spacing;
}

p {
    margin: 0 0 $small-spacing;
}

a {
    color: $action-color;
    text-decoration: none;
    transition: color $base-duration $base-timing;

    &:active,
    &:focus,
    &:hover {
        color: shade($action-color, 25%);
        text-decoration: none;
    }
}

hr {
    border-bottom: $base-border;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: $base-spacing 0;
}

img,
picture {
    margin: 0;
    max-width: 100%;
}

blockquote {
    margin: 0;
    padding-left: 4rem;
    quotes: "\201D""\201C""\2018""\2019";
    position: relative;
    font-weight: $bold;
    font-size: 1.4rem;

    &:before {
        position: absolute;
        content: open-quote;
        line-height: 0.1em;
        font-family: 'roboto slab';
        font-size: 12rem;
        top: 2.8rem;
        left: 1rem;
        z-index: -1;
        color: lighten(black, 88);
    }

    p {
        line-height: 1.4;
    }
}

cite {
    color: lighten($base-font-color, 25);
    font-style: italic;
    &:before {
        content: "\2014 \00A0";
    }
}

