.stripes-header {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: skewY(-7deg);
    pointer-events: none;
    bottom: -50px;
    z-index: -1;

    // Vars
    $height: 12rem;

    .stripe {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        height: $height;
    }

    .s0 {
        height: $height * 20;
        bottom: $height;
        background: linear-gradient(90deg, #1b2f48, #0b1d31);
    }
    .s1 {
        background: linear-gradient(90deg, #fff 50%, #f9fafb);
        right: calc(50% - 30px);
        bottom: 0;
    }
    .s2 {
        bottom: 0;
        left: calc(50% + 30px);
        background: linear-gradient(90deg, #ef9833, #802b06);
        background: linear-gradient(90deg, #f4f5f7, #938975);
    }
    .s3 {
        bottom: $height;
        left: 65%;
        background: linear-gradient(90deg, #172b46, #0d3a5a);
    }
    .s4 {
        bottom: $height * 2;
        left: calc(50% + 420px);
        background: linear-gradient(90deg, #122439, #182b45);
    }
    .s5 {
        bottom: $height * 3;
        right: 30%;
        background: linear-gradient(90deg, #192d48, #162940);
    }
    .s6 {
        bottom: $height * 4;
        background: #1b3553;
        height: $height;
    }
}

.illustration .stripes-header {
    .stripe {
        background-size: cover;
    }

    .s0 {
        background: url(/a/i/_illu/body-bg0.gif) center no-repeat;
        background-size: cover !important;
    }
    .s1 {
        background: url(/a/i/_illu/body-bg5.gif) center no-repeat;
        background: white;
    }
    .s2 {
        background: url(/a/i/_illu/body-bg2.gif) center no-repeat;
    }
    .s3 {
        background: url(/a/i/_illu/body-bg1.gif) center no-repeat;
    }
    .s4 {
        background: url(/a/i/_illu/body-bg4.gif) center no-repeat;
    }
    .s5 {
        background: url(/a/i/_illu/body-bg3.gif) center no-repeat;
    }
    .s6 {
        background: url(/a/i/_illu/body-bg3.gif) center no-repeat;
    }
}

//.stripes-header {
//    position: absolute;
//    left: 0;
//    top: 50%;
//    right: 0;
//    transform: skewY(-12deg);
//    pointer-events: none;
//    bottom: -117px;
//
//    .stripe {
//        position: absolute;
//        top: auto;
//        left: 0;
//        right: 0;
//        height: 204px;
//    }
//
//    .s0 {
//        height: 5000px;
//        bottom: 203px;
//        background: linear-gradient(90deg, #6fa8ef, #5a65db);
//    }
//    .s1 {
//        background: linear-gradient(90deg, #fff 50%, #f1f4f7);
//        right: calc(50% - 30px);
//        bottom: 0;
//    }
//    .s2 {
//        bottom: 0;
//        left: calc(50% + 30px);
//        background: linear-gradient(90deg, #f6a4eb, #555abf);
//    }
//    .s3 {
//        bottom: 204px;
//        left: 75%;
//        background: linear-gradient(90deg, #6788ea, #68bcf7);
//    }
//    .s4 {
//        bottom: 408px;
//        left: calc(50% + 410px);
//        background: linear-gradient(90deg, rgba(86, 88, 199, .35), #5658c7);
//    }
//    .s5 {
//        bottom: 612px;
//        right: 30%;
//        background: linear-gradient(90deg, #6a8dea, rgba(106, 141, 234, 0));
//    }
//    .s6 {
//        bottom: 816px;
//        background: #6772e5;
//        height: 204px;
//    }
//}
