/* //////////////////////////////////////
// Layout
//////////////////////////////////////*/


html {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
}

body {
    //@include grid-visual;
}


section.row {
    position: relative;

    .image-wrap {
        height: 80vh;
        max-height: 90vw;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .info-wrap {
        min-height: 40vh;
        padding: 4rem 0 4rem;
    }

    .anchor {
        position: absolute;
        top: -6rem;
    }

    .four {
        @include grid-push(1);
        
        @media screen and (max-width: $small-screen) {
            @include grid-push(0);
        }
    }

    h1 {
        font-size: 2rem;
        font-weight: 300;
    }

}

// Hero
.hero {
    position: relative;
    margin-bottom: 6rem;

    .image-wrap {
        height: calc(100vh - 6rem);
        //max-height: 90vw;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .title-wrap {
        position: absolute;
        bottom: 3rem;
    }

    h1 {
        color: white;
        font-weight: 100;
        font-size: 3rem;
        margin-bottom: 0;
    }
}

.navbar {
    height: 6rem;
    position: fixed;
    top: calc(100vh - 6rem);
    left: 0; right: 0;
    z-index: 2;
    background: white;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 20px rgba(lighten(black, 80), 0.1);


    li {
        display: inline-block;
        margin-right: 1rem;
    }
}
