/* //////////////////////////////////////
// Base Classes
//////////////////////////////////////*/

body.in-not-scrollable {
    overflow: hidden !important;
    height: 100vh !important;
}

// Grid
// -----------------------------------------------------------

.content-wrapper {
    //@include grid-container;
}

.image-wrapper {
    position: relative;
    overflow: hidden;

    img {
        display: block;
    }
}

.nowrap {
    white-space: nowrap;
}

.hidden {
    display: none;
}

.right {
    float: right;
}

.float-left {
    float: left;
}

.align-right, .text-right {
    text-align: right;
}

.center {
    margin: 0 auto;
}

.center-text, .align-center {
    text-align: center;
}

.mb {
    margin-bottom: $base-spacing;
}

.grey {
    background: $grey;
}

.dark {
    background: $dark;
}

.div {
    background: $base-font-color;
    display: inline-block;
    width: 2px;
    height: 1.8rem;
    margin-bottom: -0.6rem;

    &.white {
        background: white;
    }
}

// -----------------------------------------------------------
// Grid

.row {
    @include grid-container();
}

.columns {
    @include grid-column(12);
    &.ten {
        @include grid-column(10);
    }
    &.eight {
        @include grid-column(8);
    }
    &.seven {
        @include grid-column(7);
    }
    &.six {
        @include grid-column(6);
    }
    &.five {
        @include grid-column(5);
    }
    &.four {
        @include grid-column(4);
    }
    &.three {
        @include grid-column(3);
    }
    &.two {
        @include grid-column(2);
    }

    // shift
    &.shift-four {
        @include grid-shift(4);
    }
    &.shift-three {
        @include grid-shift(3);
    }
    &.shift-two {
        @include grid-shift(2);
    }
    &.shift-one {
        @include grid-shift(1);
    }

    @media screen and (max-width: $small-screen) {
        &.ten,
        &.eight,
        &.seven,
        &.six,
        &.four,
        &.three,
        &.two {
            @include grid-column(12);
            @include grid-shift(0);
            @include grid-push(0);
        }
    }
}

