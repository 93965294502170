#{$all-buttons},
.button {
    appearance: none;
    background-color: rgba($action-color, 1);
    border: 0;
    //  border-radius: $base-border-radius;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font-family;
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    line-height: 1;
    padding: $small-spacing * 1.4 $base-spacing;
    text-decoration: none;
    transition: background-color $base-duration $base-timing;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;

    &:hover,
    &:focus {
        background-color: shade($action-color, 20%);
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
            background-color: $action-color;
        }
    }
}

button,
.button,
a,
a:hover,
a:focus,
a:active,
li {
    outline: none; // this one
}

.button {
    &.red {
        background: $red;

        &:hover,
        &:focus,
        &:active {
            background-color: shade($red, 20%);
            color: white;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                background-color: $red;
            }
        }
    }

    &.blue {
        background: $blue;

        &:hover,
        &:focus,
        &:active {
            background-color: shade($blue, 20%);
            color: white;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                background-color: $blue;
            }
        }
    }

    &.brand {
        background: $color-brand;

        &:hover,
        &:focus,
        &:active {
            background-color: shade($color-brand, 20%);
            color: white;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                background-color: $color-brand;
            }
        }
    }

    &.white {
        background: white;
        color: $base-font-color;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba(white, .5);
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                background-color: white;
            }
        }
    }

    &.small-pad {
        padding: $small-spacing * 1 $base-spacing * 0.5;
    }
}
