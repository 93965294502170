// Footer
// -----------------------------------------------------------
footer.main-footer {
    position: fixed;
    right: 0; bottom: 0; left: 0;
    background-image: linear-gradient(15deg, #717381, #b5a59a);
//    background: url(/a/i/background-body.gif);
//    background: #a8bdbf;
    background: url(/a/i/background-body.jpg) center 70%;
    background-size: cover;
    padding: 1rem 0 1rem;
    height: 5rem;
    display: flex;
    align-items: center;
    overflow: hidden;

//    @media only screen and (max-width: $small-screen) {
//        height: 10rem;
//        padding: 1rem 0 3rem;
//        display: block;
//    };

    .content-wrapper {
        @include grid-column(12);
        text-align: right;
        position: relative;

//        @media only screen and (max-width: $small-screen) {
//            height: 7rem;
//        };
    }

    .icon-wrapper {
//        @media only screen and (max-width: $small-screen) {
//            position: absolute;
//            right: 0; bottom: 0; left: 0;
//            margin: 0 auto;
//            text-align: center;
//        };
    }

    svg {
        color: white;
        margin-right: 1rem;
    }
}

.typeit {
    position: absolute;
    left: 0; top: 0; bottom: 0;
    display: flex;
    align-items: center;
    color: white;
    font-weight: $book;
    white-space: nowrap;

    @media only screen and (max-width: $small-screen) {
        position: relative;
        display: none;
    };

    @media only screen and (max-width: $tiny-screen) {
        font-size: 0.8rem;
        font-weight: $regular;
    };

    .bold {
        white-space: nowrap;
        margin-left: 0.2rem;
        font-weight: $medium;
    }

    // TypeIt
    .ti-cursor {
        margin-left: -0.2rem;
    }
}
